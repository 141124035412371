import React, { useState, useContext, useEffect } from "react";
import { HiBellAlert } from "react-icons/hi2";
import { AuthContext } from "../../../context/AuthProvider";
import { updateUsernotification } from "../../../services/UserService";
import { findUserById } from "../../../services/UserService";
import Loading from "../../common/Loading/Loading";
import "./index.css";

const News = () => {
  const [openModal, setOpenModal] = useState(true);
  const { user } = useContext(AuthContext);
  const [notification, setNotification] = useState({});
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState({});
  const [newsFooterMessage, setNewsFooterMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (loading && user && user.id) handleNotification();
  }, [user]);

  const handleNotification = async () => {
    setLoading(true);
    const currentUser = user && await findUserById(user.id);
    let auxNotification = currentUser && JSON.parse(currentUser.data.notification);
    setNotification(auxNotification);
    let auxNews =
      user && user.organizer_id
        ? [
            {
              id: 0,
              title: "New website features for organizers!",
              text: "Financials: Now you can track your budget and spending, split payments, and view event results all in one place.<br> No more freaking out at the closure time!",
              src: "https://cdn-icons-png.flaticon.com/512/4256/4256863.png",
            },

            {
              id: 1,
              title: "New way of Ticketing!",
              text: "Enjoy Groovoo NFC and Dynamic QR code technology! Tickets are stored in attendees’ phone - no more emails or printing. <br>This ensures a secure and efficient ticketing experience for everyone. Learn More",
              src: "https://cdn-icons-png.flaticon.com/512/2048/2048907.png",
            },

            {
              id: 2,
              title: "NFC Tickets",
              text: "Attendees phones are their tickets. Now your events can have  Groovoo tags for hassle-free entry. <br>To unlock the NFC experience at your events make your “tag request” on “My Organization” at the “MENU”.",
              src: " https://cdn-icons-png.flaticon.com/512/6453/6453226.png",
            },

            {
              id: 3,
              title: "Dynamic QR Code",
              text: "Quick and secure access to your event through the Groovoo app when NFC is absent. <br>Screenshot or printed tickets won't work for check-in!",
              src: `https://s3.groovoo.io/${process.env.REACT_APP_STORAGE_BUCKET}/liveqrcode.gif`
            },
          ]
        : [
            {
              id: 0,
              title: "Exciting news!",
              text: "Your phone is your Ticket! No more email or printing. Enjoy Groovoo NFC and Dynamic QR code technology.",
              src: "https://cdn-icons-png.flaticon.com/512/2048/2048907.png",
            },

            {
              id: 1,
              title: "NFC technology",
              text: "Your favorite events will now have  Groovoo tags for hassle-free entry. Just show them your phone!",
              src: " https://cdn-icons-png.flaticon.com/512/6453/6453226.png",
            },

            {
              id: 2,
              title: "Live QR Code",
              text: "Groovoo Dynamic QR code will be available in your app (Profile > Tickets) when NFC is absent. <br>Screenshot or printed tickets won't get you in!",
              src: `https://s3.groovoo.io/${process.env.REACT_APP_STORAGE_BUCKET}/liveqrcode.gif`,
            },
          ];
    setNews(auxNews);
    setNewsFooterMessage(
      "We look forward to providing you with an improved ticketing experience. Thank you for choosing Groovoo!"
    );
    setLoading(false);
  };

  const handlePayload = () => {
    const payload = {
      ticketing: 1,
      groovooSocial: notification.groovooSocial,
      groovooOrganizer: notification.groovooOrganizer,
    };
    return payload;
  };

  const handleClickRight = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const handleClickLeft = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
    const payload = handlePayload();
    await updateUsernotification(user.id, payload);
    localStorage.setItem("new", false);
  };

  return (
    openModal &&
    user &&
    notification.ticketing !== 1 && (
      <div>
        {loading &&
        localStorage.getItem("new") !== "false" ? (
          <div className="loading-container"> 
            {/* <Loading/> */}
          </div>
        ) :
        newsFooterMessage.length > 1 ? (
          <div className="news-container">
            <div className="news-card">
              <div className="card-header">
                {/* <p className="groovoo-title">
                  {" "}
                  <HiBellAlert /> Groovoo Informs:
                  <span> {newsTitle}</span>
                </p> */}
              </div>
              <div className="card-content">
                {news && (
                  <div className="div-container">
                    <div className="text-center">
                      <p className="groovoo-title">
                        {" "}
                        <HiBellAlert />
                        <span> {news[currentIndex].title}</span>
                      </p>
                    </div>
                    <div>
                      {news[currentIndex].src && (
                        <img
                          className="img-center"
                          src={news[currentIndex].src}
                          width={"100px"}
                          alt=""
                        />
                      )}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: news[currentIndex].text,
                      }}
                      key={currentIndex}
                      id="message"
                    ></p>
                  </div>
                )}
              </div>
              <div className="centraliza">
                <div className="card-footer">
                  <p>{newsFooterMessage}</p>
                  <div className="justify-buttons">
                    {currentIndex > 0 && (
                      <p
                        className="close-modal"
                        onClick={() => handleClickLeft()}
                      >
                        Back
                      </p>
                    )}
                    {currentIndex === news.length - 1 ? (
                      <p
                        className="close-modal"
                        onClick={() => handleCloseModal()}
                      >
                        Finish
                      </p>
                    ) : (
                      <p
                        className="close-modal"
                        onClick={() => handleClickRight()}
                      >
                        Next
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  );
};

export default News;
