export const firebasePtBrErrors = {
    "argument-error": "",
    "app-not-authorized": "Este aplicativo, identificado pelo domínio onde está hospedado, não está autorizado a usar Firebase Authentication com a chave API fornecida.",
    "app-not-installed": "A aplicação móvel solicitada correspondente ao identificador (nome do pacote Android ou ID do pacote iOS) fornecido não está instalada neste dispositivo.",
    "captcha-check-failed": "",
    "code-expired": "",
    "cordova-not-ready": "O framework Cordova não está pronto.",
    "cors-unsupported": "Este navegador não é suportado.",
    "credential-already-in-use": "Esta credencial já está associada a uma conta de usuário diferente.",
    "custom-token-mismatch": "O token personalizado corresponde a um público diferente.",
    "requires-recent-login": "Esta operação é sensível e requer autenticação recente. Faça o login novamente antes de tentar novamente esta solicitação.",
    "dynamic-link-not-activated": "Ative os links dinâmicos no Firebase Console e concorde com os termos e condições.",
    "email-already-in-use": "O endereço de e-mail já está em uso por outra conta.",
    "expired-action-code": "O código de ação expirou.",
    "cancelled-popup-request": "Esta operação foi cancelada devido a outro popup conflitante sendo aberto.",
    "internal-error": "Ocorreu um erro interno.",
    "invalid-app-credential": "",
    "invalid-app-id": "O identificador do aplicativo móvel não está registrado para o projeto atual.",
    "invalid-user-token": "A credencial do usuário não é mais válida. O usuário deve entrar novamente",
    "invalid-auth-event": "Ocorreu um erro interno",
    "invalid-verification-code": "",
    "invalid-cordova-configuration": "Os seguintes plugins Cordova devem ser instalados para habilitar o OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappflugin-inapbrowser e cordova-plugin-customurlscheme",
    "invalid-custom-token": "O formato de token personalizado está incorreto. Por favor, verifique a documentação.",
    "invalid-email": "O endereço de e-mail está mal formatado.",
    "invalid-api-key": "Sua chave API é inválida, por favor, verifique se você a copiou corretamente.",
    "invalid-credential": "A credencial automática fornecida está mal formatada ou expirou.",
    "invalid-message-payload": "O modelo de e-mail correspondente a esta ação contém caracteres inválidos em sua mensagem. Por favor, corrija indo para a seção Auth email templates no Firebase Console.",
    "invalid-oauth-provider": "EmailAuthProvider não é suportado para esta operação. Esta operação suporta apenas provedores OAuth.",
    "unauthorized-domain": "Este domínio não é autorizado para operações OAuth para o seu projeto Firebase. Edite a lista de domínios autorizados do console do Firebase.",
    "invalid-action-code": "O código de ação é inválido. Isto pode acontecer se o código estiver mal formado, expirar ou já tiver sido usado.",
    "wrong-password": "A senha é inválida ou o usuário não tem uma senha.",
    "invalid-identifier-number": "",
    "invalid-recipient-email": "O e-mail correspondente a esta ação não enviou como o endereço de e-mail do destinatário fornecido é inválido.",
    "invalid-sender": "O modelo de e-mail correspondente a esta ação contém um e-mail ou nome do remetente inválido. Por favor, corrija indo para a seção Auth email templates no Firebase Console.",
    "invalid-verification-id": "",
    "missing-iframe-start": "Ocorreu um erro interno.",
    "auth-domain-config-required": "Certifique-se de incluir authDomain ao chamar o Firebase. initializeApp(), seguindo as instruções no console do Firebase.",
    "missing-app-credential": "",
    "missing-verification-code": "",
    "missing-identifier-number": "",
    "missing-verification-id": "",
    "app-deleted": "Esta instância do FirebaseApp foi excluída.",
    "account-exists-with-different-credential": "Já existe uma conta com o mesmo endereço de e-mail, mas com credenciais de login diferentes.",
    "network-request-failed": "Ocorreu um erro na rede (como timeout, conexão interrompida ou host inalcançável).",
    "no-auth-event": "Ocorreu um erro interno.",
    "no-such-provider": "O usuário não foi vinculado a uma conta com o provedor fornecido.",
    "operation-not-allowed": "O provedor fornecido está desabilitado para este projeto Firebase. Habilite-o no console do Firebase, na aba método de login da seção Auth.",
    "operation-not-supported-in-this-environment": "Esta operação não é suportada no ambiente em que esta aplicação está rodando. \\O \"location.protocol\" deve ser http, https ou chrome-extension e o armazenamento web deve estar habilitado.",
    "popup-blocked": "Incapaz de estabelecer uma conexão com o popup. Pode ter sido bloqueada pelo navegador.",
    "popup-closed-by-user": "O popup foi fechado pelo usuário antes de finalizar a operação",
    "provider-already-linked": "O usuário só pode ser vinculado a uma identidade para o provedor dado",
    "quota-exceeded": "",
    "redirect-cancelled-by-user": "A operação de redirecionamento foi cancelada pelo usuário antes de finalizar",
    "redirect-operation-pending": "Uma operação de redirecionamento de login já está pendente",
    "timeout": "A operação foi expirada",
    "user-token-expired": "A credencial do usuário não é mais válida. O usuário deve fazer o login novamente.",
    "too-many-requests": "Bloqueamos todas as solicitações deste dispositivo devido a atividade incomum. Tente novamente mais tarde.",
    "user-cancelled": "O usuário não concedeu a sua solicitação as permissões que solicitou.",
    "user-not-found": "Não há nenhum registro de usuário correspondente a este identificador. O usuário pode ter sido excluído.",
    "user-disabled": "A conta do usuário foi desabilitada por um administrador.",
    "user-mismatch": "As credenciais fornecidas não correspondem ao usuário previamente cadastrado.",
    "user-signed-out": "",
    "weak-password": "A senha deve ter 6 caracteres ou mais.",
    "web-storage-unsupported": "Este navegador não é suportado ou cookies e dados de terceiros podem ser desabilitados."
  };
  
  export const firebaseErrors = {
    "admin-restricted-operation": "This operation is restricted to administrators only.",
    "argument-error": "",
    "app-not-authorized": "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    "app-not-installed": "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.",
    "captcha-check-failed": "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
    "code-expired": "The SMS code has expired. Please re-send the verification code to try again.",
    "cordova-not-ready": "Cordova framework is not ready.",
    "cors-unsupported": "This browser is not supported.",
    "credential-already-in-use": "This credential is already associated with a different user account.",
    "custom-token-mismatch": "The custom token corresponds to a different audience.",
    "requires-recent-login": "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
    "dynamic-link-not-activated": "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.",
    "email-change-needs-verification": "Multi-factor users must always have a verified email.",
    "email-already-in-use": "The email address is already in use by another account.",
    "expired-action-code": "The action code has expired. ",
    "cancelled-popup-request": "This operation has been cancelled due to another conflicting popup being opened.",
    "internal-error": "An internal error has occurred.",
    "invalid-app-credential": "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
    "invalid-app-id": "The mobile app identifier is not registed for the current project.",
    "invalid-user-token": "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    "invalid-auth-event": "An internal error has occurred.",
    "invalid-verification-code": "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.",
    "invalid-continue-uri": "The continue URL provided in the request is invalid.",
    "invalid-cordova-configuration": "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
    "invalid-custom-token": "The custom token format is incorrect. Please check the documentation.",
    "invalid-dynamic-link-domain": "The provided dynamic link domain is not configured or authorized for the current project.",
    "invalid-email": "The email address is badly formatted.",
    "invalid-api-key": "Your API key is invalid, please check you have copied it correctly.",
    "invalid-cert-hash": "The SHA-1 certificate hash provided is invalid.",
    "invalid-credential": "The supplied auth credential is malformed or has expired.",
    "invalid-message-payload": "The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-multi-factor-session": "The request does not contain a valid proof of first factor successful sign-in.",
    "invalid-oauth-provider": "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.",
    "invalid-oauth-client-id": "The OAuth client ID provided is either invalid or does not match the specified API key.",
    "unauthorized-domain": "This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.",
    "invalid-action-code": "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
    "wrong-password": "The password is invalid or the user does not have a password.",
    "invalid-persistence-type": "The specified persistence type is invalid. It can only be local, session or none.",
    "invalid-phone-number": "The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].",
    "invalid-provider-id": "The specified provider ID is invalid.",
    "invalid-recipient-email": "The email corresponding to this action failed to send as the provided recipient email address is invalid.",
    "invalid-sender": "The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-verification-id": "The verification ID used to create the phone auth credential is invalid.",
    "invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
    "multi-factor-info-not-found": "The user does not have a second factor matching the identifier provided.",
    "multi-factor-auth-required": "Proof of ownership of a second factor is required to complete sign-in.",
    "missing-android-pkg-name": "An Android Package Name must be provided if the Android App is required to be installed.",
    "auth-domain-config-required": "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.",
    "missing-app-credential": "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.",
    "missing-verification-code": "The phone auth credential was created with an empty SMS verification code.",
    "missing-continue-uri": "A continue URL must be provided in the request.",
    "missing-iframe-start": "An internal error has occurred.",
    "missing-ios-bundle-id": "An iOS Bundle ID must be provided if an App Store ID is provided.",
    "missing-multi-factor-info": "No second factor identifier is provided.",
    "missing-multi-factor-session": "The request is missing proof of first factor successful sign-in.",
    "missing-or-invalid-nonce": "The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.",
    "missing-phone-number": "To send verification codes, provide a phone number for the recipient.",
    "missing-verification-id": "The phone auth credential was created with an empty verification ID.",
    "app-deleted": "This instance of FirebaseApp has been deleted.",
    "account-exists-with-different-credential": "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
    "network-request-failed": "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
    "no-auth-event": "An internal error has occurred.",
    "no-such-provider": "User was not linked to an account with the given provider.",
    "null-user": "A null user object was provided as the argument for an operation which requires a non-null user object.",
    "operation-not-allowed": "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
    "operation-not-supported-in-this-environment": 'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    "popup-blocked": "Unable to establish a connection with the popup. It may have been blocked by the browser.",
    "popup-closed-by-user": "The popup has been closed by the user before finalizing the operation.",
    "provider-already-linked": "User can only be linked to one identity for the given provider.",
    "quota-exceeded": "The project's quota for this operation has been exceeded.",
    "redirect-cancelled-by-user": "The redirect operation has been cancelled by the user before finalizing.",
    "redirect-operation-pending": "A redirect sign-in operation is already pending.",
    "rejected-credential": "The request contains malformed or mismatching credentials.",
    "second-factor-already-in-use": "The second factor is already enrolled on this account.",
    "maximum-second-factor-count-exceeded": "The maximum allowed number of second factors on a user has been exceeded.",
    "tenant-id-mismatch": "The provided tenant ID does not match the Auth instance's tenant ID",
    "timeout:": "The operation has timed out.",
    "user-token-expired": "The user's credential is no longer valid. The user must sign in again.",
    "too-many-requests": "We have blocked all requests from this device due to unusual activity. Try again later.",
    "unauthorized-continue-uri": "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.",
    "unsupported-first-factor": "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.",
    "unsupported-persistence-type": "The current environment does not support the specified persistence type.",
    "unsupported-tenant-operation": "This operation is not supported in a multi-tenant context.",
    "unverified-email": "The operation requires a verified email.",
    "user-cancelled": "The user did not grant your application the permissions it requested.",
    "auth/user-not-found": "Account not found. Enter a valid email or register a new account.",
    "auth/user-disabled": "Active your account first to Log In.",
    "user-mismatch": "The supplied credentials do not correspond to the previously signed in user.",
    "user-signed-out": "",
    "weak-password": "The password must be 6 characters long or more.",
    "web-storage-unsupported": "This browser is not supported or 3rd party cookies and data may be disabled.",
    "auth/wrong-password": "Incorrect password or maybe you had used a different login method before.",
    "auth/wrong-provider-google": "Please login using your Google Account. Email and password login is no longer available.",
    "auth/wrong-provider-apple": "The password is invalid or the user does not have an account.",
    "auth/network-request-failed": "Server connection failed. Please check your internet connectivity and retry.",
    "auth/admin-disabled": "The account corresponding to the provided access token has been disabled. Please contact our support team.",
  }