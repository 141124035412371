import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-app-polyfill/ie11";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// Translations
import global_en_us from "./utils/translations/en-us/global.json";
import global_pt_br from "./utils/translations/pt-br/global.json";
// Providers
import AuthProvider from "./context/AuthProvider";
import EventsProvider from "./context/EventsProvider";
import NewsProvider from "./context/NewsProvider";

// Import routes
import AppRoute from "./routes";

// Import Utils
import { initFunctions } from "./utils";

// Google analytics
import ReactGA from "react-ga4";
const TRACKING_ID1 = "G-EVEGJY208D"; //groovooapp.com
const TRACKING_ID2 = "G-24E964114T"; //groovoo.io
const TRACKING_ID3 = "G-5JSSTEW6PJ"; //groovoo.app
const TRACKING_ID4 = "G-Y11SC5R86J"; //groovoo.com
ReactGA.initialize([
    { trackingId: TRACKING_ID1 },
    { trackingId: TRACKING_ID2 },
    { trackingId: TRACKING_ID3 },
    { trackingId: TRACKING_ID4 },
]);

export function Root() {
  initFunctions();

  console.log("Root", LanguageDetector);

  // Translations
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      debug: true,
      interpolation: { escapeValue: false },
      fallbackLng: "en",
      supportedLngs: ["en", "pt"],
      detection: {
        order: ["navigator"],
      },
      resources: {
        en: {
          translation: global_en_us,
        },
        pt: {
          translation: global_pt_br,
        },
      },
    });

  return (
    <AuthProvider>
      <NewsProvider>
        <EventsProvider>
          <BrowserRouter basename={"/"}>
            <I18nextProvider i18n={i18n}>
              <AppRoute />
            </I18nextProvider>
          </BrowserRouter>
        </EventsProvider>
      </NewsProvider>
    </AuthProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
