import React from "react";
import News from "../components/features/News/News";

export const NewsContext = React.createContext();

function NewsProvider({ children }) {

    return (
        <NewsContext.Provider>
            <News />
            {children}
        </NewsContext.Provider>
    )
}

export default NewsProvider;

