import api from './api';

export async function getEventsByOrganizerId(id) {
    return api.get(`events/organizer/${id}`);
}

export async function getEventStatus(id) {
    return api.get(`event/${id}/event_status`);
}

export async function getTicketsStatus(id) {
    return api.get(`event/${id}/tickets_status`);
}

export async function createEvent(organizerId, event_data) {
    return api.post("event", { organizerId, ...event_data })
}

export async function editEvent(event_id, event_data) {
    return api.patch("event/" + event_id, event_data)
}

export async function createOrganizer(email, organization_data) {
    return api.post(`organizer`, { email, ...organization_data });
}

export function updateOrganizer(organizer_id, payload) {
    return api.patch(`organizer/${organizer_id}`, payload);
}

export async function getOrganization(id) {
    return api.get(`organizer/${id}`);
}

export async function getListOfOrganizer(organizer_id, user_id) {
    return api.get(`/organizer/list/permission/${organizer_id}/${user_id}`);
}

export async function addImage(data) {
    return api.post('event/add_image', data);
}

export async function removeImage(data) {
    return api.post('event/remove_image', data);
}

export async function createTicketDetail(data) {
    return api.post('ticketdetail', data);
}

export async function updateTicketDetail(id, data) {
    return api.patch(`ticketdetail/${id}`, data);
}

export async function deleteTicketDetail(id) {
    return api.delete(`ticketdetail/${id}`);
}

export async function createGuest(data) {
    return api.post('guest', data);
}

export async function deleteGuest(id) {
    return api.delete(`tickets/guest/${id}`);
}

export async function getGuestsTickets(id) {
    return api.get(`tickets/event/${id}/guests`);
}

export async function createCoupon(data) {
    return api.post('coupon', data);
}

export async function getEventCoupons(event_id) {
    return api.get(`coupon/event/${event_id}`);
}

export async function patchCoupon(id, data) {
    return api.patch(`coupon/${id}`, data);
}

export async function getAllOrganizers() {
    return api.get('organizers');
}

export async function getOrderById(order_id) {
    return api.get(`order/${order_id}`);
}

export async function updateOrderStatusById(order_id, status) {
    return api.patch(`order/${order_id}`, status);
}

export async function updateTicket(ticket_id, data) {
    return api.patch(`ticket/${ticket_id}`, data)
}

// export async function updateTicketStatusIsNot3(ticket_id, data) {
//     return api.patch(`ticket/status-is-not-3/${ticket_id}`, data)
// }

export async function getOrganizationMembers(organizer_id) {
    return api.get(`user/organizer/${organizer_id}`);
}

export async function getOrganizationMembersPermission(organizer_id) {
    return api.get(`/user/organizer/${organizer_id}/members`);
}

export async function deleteOrganizationMemberPermission(member_id) {
    return api.delete(`/delete/member/permission/${member_id}`);
}

export async function updateOrganizationMember({ email, organizer_id, role }) {
    return api.patch(`organization/organizer/user`, { email, organizer_id, role })
}

export async function deleteOrganizationMember(user_id) {
    return api.delete(`organizer/user/${user_id}`)
}

export async function getEventOrders(event_id) {
    return api.get(`orders/event/${event_id}`);
}

export async function getTicketsByOrder(order_id) {
    return api.get(`/ticket/${order_id}/order`);
}

export async function refundOrder(order_id, payload = {}) {
    /* payload = { 
        amount?: number,
        tickets?: Array<number>,
    } */
    return api.post(`refund/${order_id}`, payload);
}

export async function getTicketsByEvent(event_id) {
    return api.get(`tickets/event/${event_id}`);
}

export async function getEventTicketsByOrganizer(organizer_id) {
    return api.get(`organizer/${organizer_id}/events`);
}

export async function getAllEventTickets() {
    return api.get(`organizers/events`);
}

export async function getPartnersOrgByOrganization(organizer_id) {
    return api.get(`partners_org/${organizer_id}`);
}

export async function deletePartnerOrgById(partner_id) {
    return api.delete(`partner_org/${partner_id}`);
}

export async function createPartnerOrg(org_id, email, nickname, participation) {
    return api.post(`partner_org/${org_id}`, { email, nickname, participation });
}

export async function updatePartnerOrg(partner_id, organizer_id, nickname, participation) {
    return api.put(`/partner_org/${partner_id}/${organizer_id}`, { nickname, participation })
}

export async function getPartnerOrgByEmail(email) {
    return api.get(`/partner_org/${email}/email`);
}

export async function getPartnersByEventId(event_id) {
    return api.get(`/partner_event/${event_id}`);
}

export async function createPartnerEvent(event_id, email, nickname, participation) {
    return api.post(`partner_event/${event_id}`, { email, nickname, participation });
}

export async function deletePartnerEvent(partner_id) {
    return api.delete(`/partner_event/${partner_id}`);
}

export async function updatePartnerEvent(partner_id, nickname, participation) {
    return api.put(`/partner_event/${partner_id}`, { nickname, participation });
}

export async function getPartnerEventByEmail(event_id, email) {
    return api.get(`/partner_event/event/${event_id}/email/${email}`)
}

export async function updateOrgParticipaionInEvent(partner_id, nickname, participation) {
    return api.put(`/partner_event/${partner_id}/org_participation`, { participation, nickname })
}

export async function getExpendituresByEventId(event_id) {
    return api.get(`/ledgers/expenditure/${event_id}`)
}

export async function getAllCategories(type) {
    return api.get(`/categories_ledger/${type}`)
}

export async function getAllCategoriesGroupBy(type) {
    return api.get(`/categories_ledger/${type}/group_by`)
}

export async function getCategoriesByCategoryId(category_id) {
    return api.get(`/category_ledger/categories/${category_id}`)
}

export async function createExpenditure(event_id, payload) {
    return api.post(`/ledger/${event_id}`, payload)
}

export async function deleteLedgerById(ledger_id, event_id) {
    return api.delete(`/ledger/${ledger_id}/${event_id}`);
}

export async function getSplitById(split_id) {
    return api.get(`/split/${split_id}`);
}

export async function updateExpenditure(event_id, ledger_id, payload) {
    return api.put(`/ledger/${event_id}/${ledger_id}`, payload)
}

export async function getIncomesByEventId(event_id) {
    return api.get(`/ledgers/income/${event_id}`)
}

export async function getLedgerByEventId(event_id) {
    return api.get(`/ledgers/${event_id}`)
}

export async function updateGroovooNfcStatus(org_id) {
    return api.post(`/organizer/${org_id}/tag_request`);
}

export async function getAllOrganizersIncludedInGroovooNFC() {
    return api.get(`/organizers/nfc_validation`);
}

export async function createShipmentData(payload) {
    return api.post(`/nfc_tag/shipment`, { payload })
}

export async function getAllTagsByOrganizer(org_id) {
    return api.get(`/nfc_tag/organizer/${org_id}`)
}

export async function updateTagStatus(id, payload) {
    return api.put(`/nfc_tag/${id}`, payload)
}

export async function getCategories(event_id, type) {
    return api.get(`/ledgers/${event_id}/categories/${type}`)
}

export async function getAmount(type, event_id) {
    return api.get(`/ledgers/${type}/amount/${event_id}`)
}

export async function getAllBudgetsByEventIdAndType(event_id, type) {
    return api.get(`/budgets/event/${event_id}/type/${type}`)
}
export async function updateAmount(value, budget_id) {
    return api.post(`/budgets/${value}/update/${budget_id}`)
}

export async function getCategoriesToSub(event_id, category) {
    return api.get(`/budgets/${event_id}/categories_to_sub/${category}`)
}

export async function getIdFromCategoryLedger(category, sub_category) {
    return api.get(`/ledger/${category}/sub_category/${sub_category}`)
}

export async function updateBudget(event_id, budget_id, payload) {
    return api.put(`/budget/event_id/${event_id}/budget_id/${budget_id}`, payload)

}

export async function deleteBudgetById(event_id, budget_id) {
    return api.delete(`/budget/event_id/${event_id}/budget_id/${budget_id}`)

}

export async function createBudget(event_id, payload) {
    return api.post(`/budget/event_id/${event_id}/create_budget`, payload)

}

export async function getAllEmailsOrderByOrganizer(event_id, organizer_id) {
    return api.get(`organizer/${organizer_id}/groovoo_malling/${event_id}`)

}

export async function getAllContactsOrderByOrganizer(event_id, organizer_id) {
    return api.get(`organizer/${organizer_id}/groovoo_malling/${event_id}/contacts`)

}

export async function sendEmailToAllContacts(event_id, bodyEmail) {
    return api.post(`/groovoo_mailing/${event_id}/send_email`, bodyEmail)
}

export async function sendSMSToAllContacts(event_id, payload) {
    return api.post(`/groovoo_mailing/${event_id}/send_sms`, payload)
}


export async function uploadJsonFile(event_id, payload) {
    return api.post(`/groovoo_mailing/s3/json_file/${event_id}`, payload)
}

export async function saveCampaignEmail(event_id, payload) {
    return api.post(`/groovoo_mailing/event/${event_id}/templates/email`, payload)
}

export async function saveCampaignSMS(event_id, payload) {
    return api.post(`/groovoo_mailing/event/${event_id}/templates/sms`, payload)
}

export async function getJsonFile(type, file_name) {
    return api.get(`/groovoo_mailing/s3/json_file/download/${type}/${file_name}`)
}

export async function getCampaignsByEventId(event_id) {
    return api.get(`/groovoo_mailing/campaigns/event_id/${event_id}`)
}

export async function deleteCampaignByFileName(file_name) {
    return api.delete(`/groovoo_mailing/campaigns/${file_name}`)
}

export async function deleteCampaignById(template_id) {
    return api.delete(`/groovoo_mailing/campaigns/delete/${template_id}`)
}

export async function getCampaignById(template_id) {
    return api.get(`/groovoo_mailing/campaign/${template_id}`)
}

export async function updateCampaignById(campaign_id, payload) {
    return api.put(`/groovoo_mailing/campaign/${campaign_id}`, payload)
}

export async function editLimitOrganizer(organizer_id, new_limit) {
    return api.put(`/organizer/edit_limit/${organizer_id}`, new_limit)
}

export async function getCreditsByOrganizerId(organizer_id) {
    return api.get(`/organizer/credits/${organizer_id}`)
}

export async function updateCreditsByOrganizerId(organizer_id, payload) {
    return api.put(`/organizer/credits/${organizer_id}`, payload)
}

export async function updateEventStatus(event_id, status) {
    return api.put(`/event/${event_id}/update_status/${status}`)
}

// groovoo_mailing/event_id/${event_id}/templates
export async function createOrganizerGatewayPayment(payload) {
    return api.post(`/gateway/organizer`, payload)
}

export async function deleteOrganizerGatewayPayment(gateway_id) {
    return api.delete(`/gateway/organizer/${gateway_id}`)
}

export async function updateOrganizerGatewayPayment(gateway_id, credentials) {
    return api.put(`/gateway/organizer/${gateway_id}`, credentials)
}
export async function updateTrustfulUserByEmail(email) {
    return api.patch(`/user/update/email/${email}`);
}

export async function getTicketsByGuest(payload) {
    return api.post(`/tickets/event/guests`, payload);
}

export async function createFeed(payload) {
    return api.post(`/create/feed`, payload);
}

export async function getFeedsByOrganizerId(organizer_id) {
    return api.get(`/feeds/organizer/${organizer_id}`);
}

export async function getAllFeeds() {
    return api.get(`/feeds/all`);
}

export async function deleteFeedById(feed_id, organizer_id) {
    return api.delete(`/delete/feed/${feed_id}/${organizer_id}`);
}

export async function deleteFeedByDate(date) {
    return api.delete(`/delete/feed/${date}`);
}

export async function listAllGatewayTypes() {
    return api.get(`/gateway_types`);
}

export async function sendEmailToBlockOrder(payload) {
    return api.post(`/send_email/block_order`, payload);
}

export async function handlePermission(payload) {
    return api.post(`/permission/create`, payload);
}

export async function getUserPermission (user_id, organizer_id) {
    return api.get(`/get/user/permission/${user_id}/${organizer_id}`);
}

export async function handlePermissionUpdate(payload) {
    return api.put(`/updated/permission`, payload);
}