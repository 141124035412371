import api from './api';

export function getEvents() {
    return api.get('/ticketing_events');
}

export function getEventById(id) {
    return api.get(`/event/${id}`);
}

export async function getEventsCategories() {
    return api.get('/events/categories');
}

export function findEventByAlias(alias) {
    return api.get(`/event/alias/${alias}`);
}

export function getTicketsAmountByEvent(eventid) {
    return api.get(`/event/${eventid}/ticket_detail`);
}

export function getSectorAmountByEvent(eventid) {
    return api.get(`/event/${eventid}/sector_amount`);
}

export function getTicketsStatusByEvent(eventid) {
    return api.get(`/event/${eventid}/ticket_status`);
}

export function getHomeEvents() {
    return api.get('/events/home');
}

export function getEventBalance(eventid) {
    return api.get(`/event/${eventid}/balance`);
}

export function getEventDataTable(option) {
    return api.post('events/data', { option });
}


// export function getEventsByProximity(lat, lng, distance) {
//     return api.get(`/events/proximity/${lat}/${lng}/${distance}`);
// }

export function getCoordinatesByZipCode(zip_code) {
    return api.get(`event/coordinates/${zip_code}`);
}

export const getEventsCoordinates = () => {
    return api.get(`/events/coordinates`);
}

export function getTotalIncome(event_id) {
    return api.get(`event/total_income/event_id/${event_id}`);
}

export function getIncome(event_id) {
   return api.get(`/event/income/event_id/${event_id}`);
}

export function getTicketsRevenue(event_id) {
    return api.get(`/event/tickets_revenue/event_id/${event_id}`);
}