import React, { useState, useEffect } from 'react';
import { getEvents, getEventsCategories, getEventsCoordinates } from '../services/EventsService';
import moment from 'moment';

export const EventsContext = React.createContext();

const EventsProvider = (props) => {
    const [event, setEvent] = useState();
    const [events, setEvents] = useState([]); // events -> available events
    const [categories, setCategories] = useState([]);
    const [citys, setCitys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tickets, setTickets] = useState();
    const [orders, setOrders] = useState([]);
    const [eventsFiltered, setEventsFiltered] = useState([]);
    const [allCitiesCoordinates, setAllCitiesCoordinates] = useState([]);
    const [isFirstSelection, setIsFirstSelection] = useState(true)
    // Event filters
    const [categoryFilter, setCategoryFilters] = useState([]);
    const [isPaidFilter, setIsPaidFilter] = useState([]);
    const [cityFilter, setCityFilters] = useState([]);
    const [checkoutData, setCheckoutData] = useState();
    

    useEffect(() => {
        fetchAll();
    }, [])

    useEffect(() => {
        getEventsFiltered();
    }, [events, categoryFilter, isPaidFilter, cityFilter])

    const fetchAll = async() => {
        setLoading(true);
        await getCategories();
        const events = await getEvents();
        setEvents(events.data.reverse());
        getCitys(events.data);
        const coordinates = await getEventsCoordinates();
        setAllCitiesCoordinates(coordinates);
        setLoading(false);
    }

    const getCategories = async() => {
        let categories = (await getEventsCategories()).data;
        setCategories(categories);
        setCategoryFilters(categories.map(item => item.description));
        setIsPaidFilter(["free", "paid"]);
    }

    const getCitys = (events) => {
        const citys = events.map(item => item.address.city);
        const distinctCitys = [...new Set(citys)]
        const orderedcitys = (distinctCitys).sort();
        setCitys(orderedcitys);
        setCityFilters(orderedcitys);
    }

    const resetFilters = () => {
        setCategoryFilters(categories.map(item => item.description));
        setIsPaidFilter(["free", "paid"]);
        setCityFilters(citys);
    }

    const toggleCategoryFilter = (cat) => {
        if (categoryFilter.length === categories.length) {
            setCategoryFilters([cat]);
        } else {
            setCategoryFilters(prev => prev.includes(cat) ?
                prev.filter(e => e !== cat) : [...prev, cat])
        }
    }

    const toggleIsPaidFilter = (paid) => {
        setIsPaidFilter(prev => prev.includes(paid) ?
            prev.filter(e => e !== paid) : [...prev, paid])
    }

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Raio da Terra em km
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distância em km
        return distance;
      };

    const filterEventsByCity = (matchingCity) => {
        const filteredCities = allCitiesCoordinates.data.filter((cities) => {
          const distanceLimit = 90;
    
          const distance = calculateDistance(matchingCity.latitude, matchingCity.longitude, cities.latitude, cities.longitude);
          return distance <= distanceLimit;
        });
      
        return filteredCities;
      };

    const toggleCityFilter = (city) => {

        const matchingCity = allCitiesCoordinates.data.find(cities => cities.city === city);

        if (isFirstSelection) {
            if (matchingCity) {
              const filteredCities = filterEventsByCity(matchingCity);
              setCityFilters([...filteredCities.map(c => c.city), city]);
            } else setCityFilters([city]);
            setIsFirstSelection(false);
        } else {
            if (matchingCity) {
              const filteredCities = filterEventsByCity(matchingCity);
              setCityFilters(prev => prev.includes(city) ? prev.filter(e => e !== city) : [...prev, ...filteredCities.map(c => c.city), city]);
            } else setCityFilters(prev => prev.includes(city) ? prev.filter(e => e !== city) : [...prev, city]);
        }
    }

    const getEventsFiltered = async() => {
        let result = events;
        result = result.filter(e => categoryFilter.includes(e.category.description));
        result = result.filter(e => cityFilter.includes(e.address.city));
        result = result.filter(e => (e.payed && isPaidFilter.includes('paid')) || (!e.payed && isPaidFilter.includes('free')));
        result = [...result].sort((a, b) => a.start_at.localeCompare(b.start_at));
        setEventsFiltered(result);
        return result;
    }

    const searchEventsByText = (text) => {
        let searchEvents = [];
        events.forEach(event => {
            if (event.name.toLowerCase().match(text.toLowerCase()) || event.address.city.toLowerCase().match(text.toLowerCase()) || event.detail.toLowerCase().indexOf(text) > 1) {
                searchEvents.push(event);
            } else if (moment(event.start_at).utc().format('MMMM').toLowerCase().match(text.toLowerCase())) {
                searchEvents.push(event);
            }
        });
        searchEvents = [...searchEvents].sort((a, b) => a.start_at.localeCompare(b.start_at));
        setEventsFiltered(searchEvents);
    }

    return (
        <EventsContext.Provider
            value={{
                loading,
                event, 
                setEvent,
                events,
                tickets,
                setTickets,
                orders,
                setOrders,
                eventsFiltered,
                getEventsFiltered,
                resetFilters,
                categories, categoryFilter, toggleCategoryFilter,
                isPaidFilter, toggleIsPaidFilter,
                citys, cityFilter, toggleCityFilter, 
                setIsPaidFilter,
                setCategoryFilters,
                searchEventsByText,
                checkoutData,
                setCheckoutData,
            }}
            >
            {props.children}
        </EventsContext.Provider>
    )
}

export default EventsProvider;