import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <CircularProgress color="secondary"/>
    </div>
  );
};

export default Loading;
