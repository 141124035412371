import api from './api';

export async function findUserById(id) {
  return api.get(`/user/${id}`);
}

export async function syncUser(uid, data) {
  return api.post(`/user/${uid}/sync`, data);
}

export async function findUserByToken(token) {
  return api.get(`/user/${token}/token`);
}

export async function findUserByEmail(email) {
  return api.get(`/user/${email}/email`);
}

export async function findByEmailStatusOne(email) {
  return api.get(`/user/${email}/one/email`);
}

export const findByEmailStatusNotEqual2 = (email) => {
  return api.get(`/user/${email}/not-equal-2/email`);
}

export const findByEmailFirebaseAndDB = (email) => {
  return api.get(`/user/${email}/firebase-db/email`);
}


export async function resendUserActivationEmail(email) {
  return api.post(`/user/activation-email-resend`, { email });
}

export async function resendUserActivationCode(payload) {
  return api.post(`/user/activation-code-resend`, payload);
}

export async function createUserWithPwdProvider(data) {
  return api.post(`/user/firebase-pwd`, data);
}

export async function activeUserAccount(activation_code) {
  return api.post(`/user/${activation_code}/activate`);
}

export async function findOrdersByEmail(data) {
  return api.post(`/orderstickets`, data);
}

export async function findTicketsByOwnerId(data) {
  return api.post(`/ticketsbyowner`, data);
}

export async function transferTicket(data) {
  return api.post('/tickettransfer', data);
}

export async function claimTicket(data) {
  return api.post('/ticketclaim', data);
}

export async function cancelTicketTransfer(data) {
  return api.post('/canceltickettransfer', data);
}

export async function getUserOrganizerId(user_uid) {
  return api.get(`/user/${user_uid}/organizer`);
}

export async function updateUsernotification(user_id, data) {
  return api.post(`/user/notification/${user_id}`, data);
}

export async function getFirebaseId(email) {
  return api.get(`/user/uid/firebaseid/?email=${email}`);
}

export const updateUserProvider = (email, payload) => {
  return api.put(`/user/provider/${email}`, payload);
}

export const validateUserCheckout = (phone) => {
  return api.post(`/user/checkout/sms/${phone}`);
}

export const updateUser = (id, payload) => {
  return api.patch(`/user/${id}`, payload);
}

export async function getAllUsers() {
  return api.get('users');
}

export const decodeJWT = (token) => {
  return api.post('/user/decodeJWT', {token});
}

