import axios from 'axios';
import firebase from '../services/firebase';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
});

api.interceptors.request.use(
    (config) => {
        const u = localStorage.getItem('user') || false
        let token = false;

        try {
            token = JSON.parse(u).stsTokenManager.accessToken || false
            //token = localStorage.getItem('at') || false
        } catch (err) {
            console.log(`g5oAxiosInterceptor Invalid token. Ignoring for public usage`)
            console.log(err)
        }
        updateToken();
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    }, function (error) {
        console.log("AUTHENTICATED ERROR", error);
        //error.response && error.response.data && error.response.data.statusCode === 401 && error.response.data.code === 'auth/user-token-expired'
        if (error.response && error.response.data && error.response.data.statusCode === 401) {
            console.log("TOKEN EXPIRATION ERROR");
            firebase.auth().signOut();
            window.location.href = process.env.PUBLIC_URL + '/login';
        }
        return Promise.reject(error);
    }
);

const updateToken = async () => {
    if (firebase.auth().currentUser) {
        const refresh_token = firebase.auth().currentUser.refreshToken;
    }
}

export default api;
